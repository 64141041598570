<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #end>
          <FileUpload
            mode="basic"
            accept="image/*"
            :maxFileSize="1000000"
            label="Import"
            chooseLabel="Import"
            class="mr-2 inline-block"
          />
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <DataTable
        ref="dt"
        :value="io_data"
        v-model:selection="selectedIOs"
        dataKey="_id"
        :loading="loading"
        :paginator="true"
        :rows="50"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Modulen"
        responsiveLayout="scroll"
      >
        <template #empty> Keine IO-Einträge gefunden...</template>
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="table-header flex justify-content-between">
            <h5 class="mb-2 m-md:0 as-md-center">Module Bindings ansehen</h5>
          </div>
        </template>

        <Column selectionMode="multiple" :exportable="false"></Column>
        <Column field="moduleScheme" header="Modulschema" :sortable="true" filterField="moduleScheme"></Column>
        <Column field="modules" header="verbundene Module" :sortable="true" filterField="modules">
          <template #body="slotProps">
            <div v-for="entry in slotProps.data.modules" :key="entry">
              <span>{{ entry }}</span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
// import { FilterMatchMode } from "primevue/api";
// import store from "@/store";
import { mapGetters } from "vuex";
// import tree from "@/data/tree.json";
// import { splitNodeId, combineNodeId, combineRedisHash, mongoResponseToast, processModuleSchemeFromTree, flattenTree } from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";

export default {
  name: "moduleBindings",
  data() {
    return {
      loading: false,
      selectedIOs: [],
      io_data: null,
    };
  },
  computed: {
    ...mapGetters({
      getModuleBindings: 'opcua/getModuleBindings'
    }),
  },
  watch: {
  },
  created() {
    this.$store.dispatch('opcua/loadModuleBindings');
  },
  mounted() {
    this.getPageData();
  },
  beforeUnmount() {
    this.loading = false;
    this.io_data = null;
  },
  methods: {
    getPageData() {
      this.loading = true;
      SocketioService.getModuleBindings((err, response) => {
        if (!err && response !== null) {
          this.io_data = [ ...response ];
          this.loading = false;
        }
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
};
</script>

<style lang="scss" scoped>
  .p-multiselect-representative-option {
      display: inline-block;
      vertical-align: middle;
  }
</style>